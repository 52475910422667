import { useUserLogin } from "../hooks/useUserLogin";
import UserAvatarBorder, { UserAvatarBorderProps } from "./UserAvatarBorder";

export interface UserAvatarProps {
  userName: string;
  userAvatar?: string | null;
  userLevel: number;
  userExperience: number;
  userProgress?: number;
  userProgressDescription?: string;
  borderStyle: UserAvatarBorderProps["type"];
  dark?: boolean;
  showProgress?: boolean;
}

const UserAvatar = ({
  userName,
  userAvatar,
  userLevel,
  userProgress,
  userProgressDescription,
  borderStyle,
  dark,
  showProgress,
}: UserAvatarProps) => {
  const { toggleBorderStyle } = useUserLogin();

  const avatarStyle = (): React.CSSProperties => {
    const result: React.CSSProperties = {};

    switch (borderStyle) {
      case "league-of-legends":
        result.borderRadius = "50%";
        break;
    }

    return result;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ position: "relative", width: "40px", height: "40px" }} onClick={toggleBorderStyle}>
        {userAvatar ? (
          <img src={userAvatar} alt={userName} style={avatarStyle()} />
        ) : (
          <svg height="40" viewBox="0 0 40 40" width="40" style={avatarStyle()}>
            <defs id="defs2" />
            <path d="M 0,40 H 40 V 0 H 0 Z" fill="#a6cfff" id="path1" />
            <path
              d="m 23.8763,23.7117 c 2.6496,-1.3952 4.4562,-4.1758 4.4562,-7.3784 C 28.3325,11.731 24.6015,8 19.9991,8 c -4.6023,0 -8.3333,3.731 -8.3333,8.3333 0,3.2027 1.8067,5.9835 4.4565,7.3786 -3.5485,1.352 -6.258,4.5065 -7.0973,8.3939 -0.1161,0.538 0.1799,1.0903 0.7218,1.1884 C 11.6916,33.6461 14.9123,34 20.0012,34 c 5.0888,0 8.3095,-0.3539 10.2543,-0.7058 0.5399,-0.0977 0.8352,-0.6479 0.7196,-1.184 -0.8382,-3.8896 -3.5486,-7.0461 -7.0988,-8.3985 z"
              fill="#1575e5"
              id="path2"
            />
          </svg>
        )}
        <UserAvatarBorder type={borderStyle} level={userLevel} />
      </div>
      <div style={{ marginLeft: "15px", flexGrow: 1 }}>
        <div>
          <b>{userName}</b>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ flexGrow: 1, backgroundColor: dark ? "#00000088" : "#ffffff88" }}
            title={userProgressDescription}
          >
            <div
              style={{
                backgroundColor: "#fff",
                height: "10px",
                width: `${(userProgress ?? 0) * 100}%`,
                filter: "blur(1px)",
                boxShadow: "0 0 5px 2px #0ff",
              }}
            ></div>
          </div>
          {showProgress && userProgress && (
            <div style={{ marginLeft: "10px" }}>
              {Math.round(userProgress * 100)}% ({userProgressDescription})
            </div>
          )}
        </div>
        <div>Level {userLevel}</div>
      </div>
    </div>
  );
};

export default UserAvatar;
