import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { PRICE_THRESHOLD } from "../constants";

export enum ItemType {
  CD = "CD",
  DVD = "DVD",
  BLUERAY = "Blu-ray",
  AUDIOBOOK = "Hörbuch",
}

export enum OfferCondition {
  NEW = "neu",
  LIKE_NEW = "neuwertig",
  VERY_GOOD = "sehr gut",
  GOOD = "gut",
  ACCEPTABLE = "akzeptabel",
}

export enum CdType {
  ALBUM = "Album",
  MAXI = "Maxi-Single",
}

export enum DvdType {
  MOVIE = "Film",
  SERIES = "Serie",
  MINI_SERIES = "Mini-Serie",
  TEACHING = "Lehr-DVD",
}

export enum DvdFormat {
  DVD = "DVD",
  BLURAY = "Blu-ray",
}

export enum AudiobookFormat {
  AUDIO_CD = "Audio-CD",
}

export enum Language {
  GERMAN = "deutsch",
  ENGLISH = "englisch",
}

export interface PropertiesCd {
  __component?: string;
  artist: string;
  title: string;
  cdType: CdType;
  genre: string;
}

export interface PropertiesDvd {
  __component?: string;
  title: string;
  FSK: number;
  dvdType: DvdType;
  format: DvdFormat;
  language: Language;
  genre: string;
}

export interface PropertiesBlueRay extends PropertiesDvd {
  format: DvdFormat.BLURAY;
}

export interface PropertiesAudiobook {
  __component?: string;
  author: string;
  title: string;
  language: Language;
  format: AudiobookFormat;
  genre: string;
}

export type PropertiesAll = PropertiesCd | PropertiesDvd | PropertiesBlueRay | PropertiesAudiobook;

export interface ScanItem<T extends ItemType, P extends PropertiesAll> {
  id?: number;
  type: T;
  ean: string;
  price: number;
  properties?: P[];
  offers?: { data: { id: number; attributes: Offer<T, P>[] } };
  image?: string;
  saved?: boolean;
  highlight?: boolean | "primary" | "secondary" | "error" | "warning" | "info" | "success";
  user?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type ScanItemCd = ScanItem<ItemType.CD, PropertiesCd>;
export type ScanItemDvd = ScanItem<ItemType.DVD, PropertiesDvd>;
export type ScanItemBlueRay = ScanItem<ItemType.BLUERAY, PropertiesBlueRay>;
export type ScanItemAudiobook = ScanItem<ItemType.AUDIOBOOK, PropertiesAudiobook>;
export type ScanItemAll = ScanItemCd | ScanItemDvd | ScanItemBlueRay | ScanItemAudiobook;

export interface Offer<T extends ItemType, P extends PropertiesAll> {
  id?: number;
  ascendingId: number;
  condition: OfferCondition;
  paletteId?: string;
  price: number;
  item?: { data: { id: number; attributes: ScanItem<T, P> } };
  user?: string;
  createdAt?: string;
  updatedAt?: string;
}

export type OfferCd = Offer<ItemType.CD, PropertiesCd>;
export type OfferDvd = Offer<ItemType.DVD, PropertiesDvd>;
export type OfferBlueRay = Offer<ItemType.BLUERAY, PropertiesBlueRay>;
export type OfferAudiobook = Offer<ItemType.AUDIOBOOK, PropertiesAudiobook>;
export type OfferAll = OfferCd | OfferDvd | OfferBlueRay | OfferAudiobook;

function ItemHistory({ items }: { items: ScanItemAll[] }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>EAN</TableCell>
            <TableCell>Titel</TableCell>
            <TableCell>Preis</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={`${item.id}_${index}`}>
              <TableCell>{item.ean}</TableCell>
              <TableCell>
                {JSON.stringify(item)}
                <Typography>{item.properties?.[0]?.title}</Typography>
              </TableCell>
              <TableCell>
                <Typography color={item.price < PRICE_THRESHOLD ? "error" : "primary"}>
                  {item.price?.toFixed(2) ?? "0.00"} €
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ItemHistory;
