import { useCallback, useEffect, useState } from "react";

export const useRedirect = () => {
  const [url, setUrl] = useState<URL>(new URL(window.location.href));

  useEffect(() => {
    setUrl(new URL(window.location.href));
  }, []);

  const redirectUrl = useCallback(
    (redirectUrl: string) => {
      const { host, protocol } = url;
      return `${protocol}//${host}/redirect/${encodeURIComponent(redirectUrl)}`;
    },
    [url]
  );

  return {
    redirectUrl,
  };
};
