import {
  CdType,
  DvdType,
  ItemType,
  PropertiesCd,
  PropertiesDvd,
  DvdFormat,
  PropertiesAudiobook,
  Language,
  AudiobookFormat,
} from "./components/ItemHistory";

enum ChangeLogType {
  Fix = "fix",
  Feature = "feature",
}

export const HISTORY_SIZE = 10;
export const PRICE_THRESHOLD = 5;
export const STRAPI_URI = "/strapi/api/";
export const BACKEND_V2_URI = "/basev2/";
export const STRAPI_TOKEN =
  "6060d9a83cb6ad14d9b346e48752cbf333f35d24ac0ab5202ec26368dce8979c0d03b7219590f7eea3f0b2882a4c9a7e8c3b529d3669fb0165a4856de9dba3650b6b18ae7c7e39f06560beff87ff589c80c57e59fd8202dd53176df62a8c71354b7375d5c5775abffdd00f6810c58a534cc946a84b3892ec952f56f5659007d6";
export const EBAY_FILTER = "_from=R40&_sop=15&LH_BIN=1&rt=nc&LH_PrefLoc=1";
export const EBAY_FILTER_SOLD = "_from=R40&_sop=15&LH_BIN=1&rt=nc&LH_PrefLoc=1&LH_Sold=1&LH_Complete=1";


export const DEFAULT_PROPERTIES: Record<ItemType, PropertiesCd | PropertiesDvd | PropertiesAudiobook> = {
  [ItemType.CD]: {
    artist: "",
    title: "",
    cdType: CdType.ALBUM,
    language: Language.GERMAN,
    genre: "CD",
  },
  [ItemType.DVD]: {
    title: "",
    FSK: 0,
    dvdType: DvdType.MOVIE,
    format: DvdFormat.DVD,
    language: Language.GERMAN,
    genre: "Drama",
  },
  [ItemType.BLUERAY]: {
    title: "",
    FSK: 0,
    dvdType: DvdType.MOVIE,
    format: DvdFormat.BLURAY,
    language: Language.GERMAN,
    genre: "Drama",
  },
  [ItemType.AUDIOBOOK]: {
    author: "",
    title: "",
    language: Language.GERMAN,
    format: AudiobookFormat.AUDIO_CD,
    genre: "Drama",
  },
};

export const VERSION = "1.5.0";
export const DISPLAY_CHANGELOG_AGE = 60 * 24 * 24 * 5;
export const CHANGELOG = [
  {
    version: "1.5.0",
    date: new Date(1722457468099),
    changes: [
      {
        type: ChangeLogType.Feature,
        description: "Nutzer-Login",
      },
      {
        type: ChangeLogType.Feature,
        description: "Nutzer Levels und Fortschrittsanzeige",
      },
      {
        type: ChangeLogType.Feature,
        description: "Startseite mit Nutzerinformationen",
      },
      {
        type: ChangeLogType.Fix,
        description: "Speichern aller DVD Genres",
      },
      {
        type: ChangeLogType.Fix,
        description: "Angleichen der Farben bei Scan-Alpha",
      },
      {
        type: ChangeLogType.Fix,
        description: "Paletten ID wird geloggt",
      },
    ],
  },
  {
    version: "1.4.2",
    date: new Date(1708414710219),
    changes: [
      {
        type: ChangeLogType.Fix,
        description: "Fehler beim Speichern von DVDs",
      },
    ]
  },
  {
    version: "1.4.1",
    date: new Date(1706862367016),
    changes: [
      {
        type: ChangeLogType.Fix,
        description: "Sammeln Button dauerhaft speichern",
      },
      {
        type: ChangeLogType.Fix,
        description: "Hörbuch Kategorien hinzugefügt",
      },
      {
        type: ChangeLogType.Feature,
        description: "Amazon Daten werden bei Scan geladen",
      },
      {
        type: ChangeLogType.Feature,
        description: "Artikeltyp wird auf den ausgewählten Typ angewandt",
      },
      {
        type: ChangeLogType.Feature,
        description: "EAN Farb-Scan Oberfläche vereinfacht",
      },
    ]
  },
  {
    version: "1.4.0",
    date: new Date(1705918184659),
    changes: [
      {
        type: ChangeLogType.Feature,
        description: "Bei neuen EANs wird Keepa durchsucht und die Produktinformationen angezeigt",
      },
    ],
  },
  {
    version: "1.3.12",
    date: new Date(1701421697750),
    changes: [
      {
        type: ChangeLogType.Fix,
        description: "Suche im Export wird beim Export angewandt",
      },
      {
        type: ChangeLogType.Feature,
        description: "Anzeige von mehr Daten im Export",
      },
      {
        type: ChangeLogType.Fix,
        description: "Highlight wird korrekt gespeichert",
      },
    ],
  },
  {
    version: "1.3.11",
    date: new Date(1700550975649),
    changes: [
      {
        type: ChangeLogType.Fix,
        description: "Bluray Export fix",
      },
    ],
  },
  {
    version: "1.3.10",
    date: new Date(1698832536232),
    changes: [
      {
        type: ChangeLogType.Feature,
        description: "Blu-ray Format hinzugefügt",
      },
      {
        type: ChangeLogType.Feature,
        description: "Artikel zum markieren (Blauer Hintergrund)",
      },
      {
        type: ChangeLogType.Fix,
        description: "Palette im Gesamtexport enthalten",
      },
    ],
  },
  {
    version: "1.3.9",
    date: new Date(1698364800000),
    changes: [
      {
        type: ChangeLogType.Fix,
        description: "Titellängen Prüfung für <80 Zeichen",
      },
    ],
  },
  {
    version: "1.3.8",
    date: new Date(1698364800000),
    changes: [
      {
        type: ChangeLogType.Feature,
        description: "Versionsnachricht bei Update anzeigen",
      },
      {
        type: ChangeLogType.Feature,
        description: "Filter nach Datum beim Export",
      },
      {
        type: ChangeLogType.Feature,
        description: "Scan Log speichern",
      },
      {
        type: ChangeLogType.Fix,
        description: "Verbesserte Fehlermeldungen für Felder",
      },
    ],
  },
  {
    version: "1.3.7",
    date: new Date(1698278400000),
    changes: [
      {
        type: ChangeLogType.Feature,
        description: "Versionsverlauf anzeigen",
      },
      {
        type: ChangeLogType.Fix,
        description: "Trennen von Datum und Uhrzeit im Export",
      },
    ],
  },
];
