import { Box, Stack } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <Stack sx={{ height: "100%" }}>
      <Header />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  );
}

export default Layout;
