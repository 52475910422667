import { useCallback } from "react";

export const useDownload = (defaultName = "file", defaultFileType = "csv") => {
  const download = useCallback(
    (data: string, name = defaultName, fileType = defaultFileType) => {
      const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", `${name}.${fileType}`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
    },
    [defaultFileType, defaultName]
  );

  return download;
};
