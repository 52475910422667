import { createContext, useContext, useState, ReactNode } from "react";
import { UserAvatarBorderProps } from "../components/UserAvatarBorder";
import { BACKEND_V2_URI } from "../constants";
import { enqueueSnackbar } from "notistack";

// Define the shape of the context data
interface UserLoginContextData {
  userId: number | null;
  userName: string | null;
  userAvatar: string | null;
  userLevel: number;
  userExperience: number;
  userProgress?: number;
  userProgressDescription?: string;
  userBorderStyle: UserAvatarBorderProps["type"];
  userToken: string | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  setUserLevel: (level: number) => void;
  setUserExperience: (experience: number) => void;
  setUserBorderStyle: (borderStyle: UserAvatarBorderProps["type"]) => void;
  toggleBorderStyle: () => void;
  reloadStats: () => void;
}

// Create a context
const UserLoginContext = createContext<UserLoginContextData | undefined>(undefined);
const borderStyleRotation: UserAvatarBorderProps["type"][] = ["rocket-league", "league-of-legends", "us-army"];

// Provider component
export const UserLoginProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<number | null>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const [userLevel, setUserLevel] = useState<number>(0);
  const [userExperience, setUserExperience] = useState<number>(0);
  const [userProgress, setUserProgress] = useState<number | undefined>(undefined);
  const [userProgressDescription, setUserProgressDescription] = useState<string | undefined>(undefined);
  const [userBorderStyle, setUserBorderStyle] = useState<UserAvatarBorderProps["type"]>("rocket-league");
  const [userToken, setUserToken] = useState<string | null>(null);

  const login = async (username: string, password: string) => {
    const response = await fetch(`${BACKEND_V2_URI}auth/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();

    if (!response.ok || !data.accessToken) {
      enqueueSnackbar("Falscher Nutzername oder Passwort", { variant: "error" });
      return;
    }

    const userDataResponse = await fetch(`${BACKEND_V2_URI}user/me`, {
      headers: { Authorization: `Bearer ${data.accessToken}` },
    });
    const userData = await userDataResponse.json();

    setUserName(username);
    setUserId(data.user.userId);
    setUserToken(data.accessToken);
    setUserAvatar(null);
    setUserLevel(userData.stats.level);
    setUserExperience(userData.stats.experience);
    setUserProgress(userData.stats.progress);
    setUserProgressDescription(userData.stats.progressDescription);
  };
  const logout = () => {
    setUserId(null);
    setUserName(null);
    setUserToken(null);
  };
  const toggleBorderStyle = () => {
    const currentIndex = borderStyleRotation.indexOf(userBorderStyle);
    setUserBorderStyle(borderStyleRotation[(currentIndex + 1) % borderStyleRotation.length]);
  };
  const reloadStats = async () => {
    const userDataResponse = await fetch(`${BACKEND_V2_URI}user/me`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    const userData = await userDataResponse.json();

    setUserLevel(userData.stats.level);
    setUserExperience(userData.stats.experience);
  };

  return (
    <UserLoginContext.Provider
      value={{
        userId,
        userName,
        userAvatar,
        userLevel,
        userExperience,
        userProgress,
        userProgressDescription,
        userBorderStyle,
        userToken,
        login,
        logout,
        setUserLevel,
        setUserExperience,
        setUserBorderStyle,
        toggleBorderStyle,
        reloadStats,
      }}
    >
      {children}
    </UserLoginContext.Provider>
  );
};

// Hook to use the context
export const useUserLogin = () => {
  const context = useContext(UserLoginContext);
  if (context === undefined) {
    throw new Error("useUserLogin must be used within a UserLoginProvider");
  }

  return context;
};
