import { Clear, QrCodeScanner, Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";

export interface ScanFormSimplifiedRef {
  reset: () => void;
}

export interface ScanFormSimplifiedProps {
  onScan: (ean: string) => void;
  paletteId: string;
  loading?: boolean;
}

const ScanFormSimplified = forwardRef<ScanFormSimplifiedRef, ScanFormSimplifiedProps>(
  ({ onScan, paletteId, loading }, ref) => {
    const [ean, setEan] = useState("");
    const inputRef = useRef<HTMLInputElement>();

    const reset = useCallback(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, []);

    useImperativeHandle(ref, () => ({ reset }));

    return (
      <Stack direction="row" spacing={1} alignItems="start" sx={{ width: "100%" }}>
        <FormControl fullWidth>
          <TextField
            autoFocus
            size="small"
            placeholder="EAN"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <QrCodeScanner />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => reset()}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputRef={inputRef}
            onChange={(e) => setEan(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onScan(ean);
            }}
            value={ean}
          />
          <FormHelperText>EAN Scannen</FormHelperText>
        </FormControl>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={() => onScan(ean)}
          disabled={!paletteId}
          sx={{ py: 1, px: 5 }}
        >
          <Search sx={{ mr: 1 }} />
          Suchen
        </LoadingButton>
      </Stack>
    );
  }
);

export default ScanFormSimplified;
