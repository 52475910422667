import { FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useMemo } from "react";
import { DvdFormat, DvdType, Language, PropertiesDvd } from "./ItemHistory";

function EditDvdProperties({
  properties,
  setProperties,
  validate,
  format
}: {
  properties: Partial<PropertiesDvd>;
  setProperties: (properties: Partial<PropertiesDvd>) => void;
  validate?: boolean;
  format?: DvdFormat;
}) {
  const fsk = useMemo(() => [0, 6, 12, 16, 18], []);
  const genres = useMemo(
    () => [
      "Mittelalter",
      "Bollywood",
      "Sport",
      "Anime",
      "Komödie",
      "Dokumentation",
      "Fantasy",
      "Western",
      "Science-Fiction",
      "Soap",
      "Musik",
      "Krimi",
      "Love",
      "Drama",
      "Action",
      "Horror",
      "Comedy",
      "Kinder",
      "Gesundheit",
      "Thriller",
    ],
    []
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {format === DvdFormat.BLURAY ? "Blu-ray" : "DVD"} Einstellungen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={validate && !properties.title} fullWidth>
          <TextField
            size="small"
            onChange={(e) => setProperties({ ...properties, title: e.target.value })}
            value={properties.title}
          />
          <FormHelperText>Titel</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && properties.FSK === undefined} fullWidth>
          <Select
            value={properties.FSK}
            type="number"
            label="Genre"
            displayEmpty
            size="small"
            onChange={(e) =>
              setProperties({
                ...properties,
                FSK: typeof e.target.value === "number" ? e.target.value : parseInt(e.target.value),
              })
            }
          >
            {fsk.map((fsk) => (
              <MenuItem key={fsk} value={fsk}>
                {fsk}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>FSK</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && !properties.language} fullWidth>
          <Select
            value={properties.language}
            label="Language"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, language: e.target.value as Language })}
          >
            {Object.values(Language).map((language) => (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Sprache</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && !properties.dvdType} fullWidth>
          <Select
            value={properties.dvdType}
            label="Typ"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, dvdType: e.target.value as DvdType })}
          >
            {Object.values(DvdType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Typ</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl error={validate && !properties.format} fullWidth>
          <Select
            value={format ?? properties.format}
            label="Format"
            displayEmpty
            size="small"
            disabled={format !== undefined}
            onChange={(e) => setProperties({ ...properties, format: e.target.value as DvdFormat })}
          >
            {Object.values(DvdFormat).map((format) => (
              <MenuItem key={format} value={format}>
                {format}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Produkttyp</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl error={validate && !properties.genre} fullWidth>
          <Select
            value={properties.genre}
            label="Genre"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, genre: e.target.value })}
          >
            {genres.map((genre) => (
              <MenuItem key={genre} value={genre}>
                {genre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Genre</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default EditDvdProperties;
