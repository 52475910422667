import {
  AddCircleOutline,
  AutoFixHigh,
  ExpandLess,
  ExpandMore,
  ListAlt,
  ManageHistory,
  QrCodeScanner,
  SupervisedUserCircle,
  ListAltOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Toolbar,
} from "@mui/material";
import { Link } from "react-router-dom";
import { CHANGELOG, DISPLAY_CHANGELOG_AGE, VERSION } from "../constants";
import { useEffect, useRef, useState } from "react";
import UserAvatar from "../components/UserAvatar";
import { useUserLogin } from "../hooks/useUserLogin";

function HeaderV2() {
  const { userName, userAvatar, userLevel, userExperience, userProgress, userProgressDescription, userBorderStyle } =
    useUserLogin();

  const [showVersionInfo, setShowVersionInfo] = useState(
    CHANGELOG.some((change) => change.date.getTime() < Date.now() + DISPLAY_CHANGELOG_AGE)
  );
  const [changelog, setChangelog] = useState(CHANGELOG.map((change, index) => ({ ...change, open: index === 0 })));
  const [versionInfoAnchor, setVersionInfoAnchor] = useState<null | HTMLElement>(null);
  const versionRef = useRef<HTMLButtonElement>(null);

  useEffect(() => setVersionInfoAnchor(versionRef.current), [versionRef]);

  return userName ? (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ flexGrow: 1 }}>
          <Link to="/v2/scan">
            <Button variant="contained" color="primary" disableElevation startIcon={<QrCodeScanner />}>
              EAN Scannen
            </Button>
          </Link>
          <Link to="/v2/scan-alpha">
            <Button variant="contained" color="primary" disableElevation startIcon={<QrCodeScanner />}>
              Scan Alpha
            </Button>
          </Link>
          <Link to="/v2/overview">
            <Button variant="contained" color="primary" disableElevation startIcon={<ListAlt />}>
              Übersicht
            </Button>
          </Link>
          {userName === "admin" && (
            <>
              <Link to="/v2/overview/export-all">
                <Button variant="contained" color="primary" disableElevation startIcon={<ListAlt />}>
                  Gesamtübersicht
                </Button>
              </Link>
              <Link to="/v2/users">
                <Button variant="contained" color="primary" disableElevation startIcon={<SupervisedUserCircle />}>
                  Benutzer
                </Button>
              </Link>
              <Link to="/v2/logs">
                <Button variant="contained" color="warning" disableElevation startIcon={<ListAltOutlined />}>
                  Logs
                </Button>
              </Link>
            </>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <UserAvatar
            userName={userName}
            userAvatar={userAvatar}
            userExperience={userExperience}
            userLevel={userLevel}
            userProgress={userProgress}
            userProgressDescription={userProgressDescription}
            borderStyle={userBorderStyle}
          />
          <Button
            color="inherit"
            sx={{ textTransform: "none", ml: 2 }}
            ref={versionRef}
            onClick={() => setShowVersionInfo(true)}
          >
            v{VERSION}
          </Button>
        </Toolbar>
      </Container>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={versionInfoAnchor}
        open={showVersionInfo && !!versionInfoAnchor}
        onClose={() => setShowVersionInfo(false)}
      >
        {changelog.map((change, index) => (
          <List key={change.version}>
            <ListItemButton
              onClick={() =>
                setChangelog((changelog) =>
                  changelog.map((change, i) => (i === index ? { ...change, open: !change.open } : change))
                )
              }
            >
              <ListItemIcon>
                <ManageHistory />
              </ListItemIcon>
              <ListItemText primary={`v${change.version}`} />
              {change.open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={change.open} timeout="auto">
              <List component="div" disablePadding>
                {change.changes.map((entry, index) => (
                  <ListItemButton key={index} sx={{ pl: 4 }}>
                    <ListItemIcon>{entry.type === "feature" ? <AddCircleOutline /> : <AutoFixHigh />}</ListItemIcon>
                    <ListItemText primary={entry.description} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        ))}
      </Popover>
    </AppBar>
  ) : (
    <></>
  );
}

export default HeaderV2;
