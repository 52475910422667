import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { Clear, Download, Search } from "@mui/icons-material";
import { ItemType, OfferAll, PropertiesAudiobook, PropertiesCd, PropertiesDvd } from "../components/ItemHistory";
import { PRICE_THRESHOLD, STRAPI_TOKEN, STRAPI_URI } from "../constants";
import { enqueueSnackbar } from "notistack";
import { useDownload } from "../hooks/useDownload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function OverviewRoute() {
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<ItemType>(ItemType.CD);
  const [offers, setOffers] = useState<OfferAll[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const download = useDownload("items", "csv");

  const getOfferPage = useCallback(
    (page: number, pageSize: number, populate: string[]) => {
      const filters = search
        ? [
            `filters[item][type][$eq]=${type}`,
            `filters[$or][0][item][ean][$contains]=${search}`,
            `filters[$or][1][item][name][$containsi]=${search}`,
          ]
        : [`filters[item][type][$eq]=${type}`];
      if (dateFrom) filters.push(`filters[createdAt][$gte]=${dateFrom.toISOString()}`);
      if (dateTo) filters.push(`filters[createdAt][$lte]=${dateTo.toISOString()}`);

      const populateString = (populate.length ? "&" : "") + populate.map((p, i) => `populate[${i}]=${p}`).join("&");

      return fetch(
        `${STRAPI_URI}offers?${filters.join(
          "&"
        )}${populateString}&pagination[pageSize]=${pageSize}&pagination[page]=${page}`,
        {
          headers: { Authorization: `Bearer ${STRAPI_TOKEN}` },
        }
      );
    },
    [search, dateFrom, dateTo, type]
  );

  const exportItems = useCallback(async () => {
    setLoading(true);

    try {
      const offers: OfferAll[] = [];
      let total = 0;
      let page = 1;

      do {
        const response = await getOfferPage(page, 50, ["item", "item.properties"]);
        const { data, error, meta } = await response.json();
        if (error) throw new Error(error);

        offers.push(...data.map(({ id, attributes }: any) => ({ id, ...attributes })));
        total = meta.pagination.total;
        page++;
      } while (offers.length < total);

      const header = [];
      const body = [];
      switch (type) {
        case ItemType.CD:
          header.push(
            "Interpret",
            "Titel",
            "Genre",
            "Produktart",
            "SKU",
            "EAN",
            "Bild URL",
            "Preis",
            "Zustand",
            "Palette",
            "Nutzer",
            "Datum",
            "Uhrzeit"
          );
          body.push(
            ...offers.map((offer) => {
              const item = offer.item?.data.attributes;
              const properties = item?.properties?.[0] as PropertiesCd | undefined;
              return [
                properties?.artist,
                properties?.title,
                properties?.genre,
                properties?.cdType,
                offer.ascendingId,
                item?.ean,
                item?.image,
                offer.price?.toFixed(2).replace(".", ",") ?? "0,00",
                offer.condition,
                offer.paletteId,
                offer.user,
                offer.createdAt ? new Date(offer.createdAt).toLocaleDateString() : null,
                offer.createdAt ? new Date(offer.createdAt).toLocaleTimeString() : null,
              ];
            })
          );
          break;
        case ItemType.DVD:
        case ItemType.BLUERAY:
          header.push(
            "Titel DVD",
            "Preis",
            "Produktformat",
            "Sprache",
            "FSK",
            "Produktart",
            "Genre",
            "SKU",
            "EAN",
            "Bild URL",
            "Zustand",
            "Palette",
            "Nutzer",
            "Datum",
            "Uhrzeit"
          );
          body.push(
            ...offers.map((offer) => {
              const item = offer.item?.data.attributes;
              const properties = item?.properties?.[0] as PropertiesDvd | undefined;
              return [
                properties?.title,
                offer.price?.toFixed(2).replace(".", ",") ?? "0,00",
                properties?.format,
                properties?.language,
                properties?.FSK,
                properties?.dvdType,
                properties?.genre,
                offer.ascendingId,
                item?.ean,
                item?.image,
                offer.condition,
                offer.paletteId,
                offer.user,
                offer.createdAt ? new Date(offer.createdAt).toLocaleDateString() : null,
                offer.createdAt ? new Date(offer.createdAt).toLocaleTimeString() : null,
              ];
            })
          );
          break;
        case ItemType.AUDIOBOOK:
          header.push(
            "Titel Hörbuch",
            "Autor",
            "Preis",
            "Sprache",
            "Format",
            "Genre",
            "SKU",
            "ISBN",
            "Bild URL",
            "Zustand",
            "Palette",
            "Nutzer",
            "Datum",
            "Uhrzeit"
          );
          body.push(
            ...offers.map((offer) => {
              const item = offer.item?.data.attributes;
              const properties = item?.properties?.[0] as PropertiesAudiobook | undefined;
              return [
                properties?.title,
                properties?.author,
                offer.price?.toFixed(2).replace(".", ",") ?? "0,00",
                properties?.language,
                properties?.format,
                properties?.genre,
                offer.ascendingId,
                item?.ean,
                item?.image,
                offer.condition,
                offer.paletteId,
                offer.user,
                offer.createdAt ? new Date(offer.createdAt).toLocaleDateString() : null,
                offer.createdAt ? new Date(offer.createdAt).toLocaleTimeString() : null,
              ];
            })
          );
          break;
      }
      const csv = [
        header.join(";"),
        ...body.map((row) =>
          row
            .map((value) => {
              if (typeof value === "string" && value.includes(";")) return `"${value.replace(/"/g, '""')}"`;
              return value;
            })
            .join(";")
        ),
      ];

      const date = new Date();
      const dateParts = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
      ];
      download(csv.join("\n"), `items_export_${type}_${dateParts.join("")}`);
    } catch (error) {
      enqueueSnackbar("Fehler beim Exportieren der Artikel", { variant: "error" });
    }

    setLoading(false);
  }, [download, type, getOfferPage]);

  const searchItems = useCallback(
    async (resetToPage?: number) => {
      setLoading(true);
      if (resetToPage !== undefined) setPage(resetToPage);

      try {
        const response = await getOfferPage((resetToPage ?? page) + 1, rowsPerPage, ["item", "item.properties"]);
        const { data, meta, error } = await response.json();

        if (error) throw new Error(error);

        setOffers(data.map(({ id, attributes }: any) => ({ id, ...attributes })));
        setCount(meta.pagination.total);
      } catch (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      }

      setLoading(false);
    },
    [page, rowsPerPage, getOfferPage]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    searchItems();
  }, [page, searchItems, type]);

  return (
    <Container sx={{ marginTop: 8 }}>
      <Toolbar>
        <Select
          value={type}
          label="Typ"
          displayEmpty
          size="small"
          onChange={(e) => setType(e.target.value as ItemType)}
          sx={{ mr: 2 }}
        >
          {Object.values(ItemType).map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Von"
            value={dateFrom}
            onChange={(e) => setDateFrom(e)}
            slotProps={{
              textField: {
                size: "small",
                sx: { maxWidth: "150px", mr: 2 },
              },
            }}
          />
          <DatePicker
            label="Bis"
            value={dateTo}
            onChange={(e) => setDateTo(e)}
            slotProps={{
              textField: {
                size: "small",
                sx: { maxWidth: "150px", mr: 2 },
              },
            }}
          />
        </LocalizationProvider>
        <Input
          placeholder="Suche"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") searchItems(0);
          }}
          value={search}
          endAdornment={
            <IconButton onClick={() => setSearch("")}>
              <Clear />
            </IconButton>
          }
        />
        <IconButton sx={{ mr: "auto" }} disabled={loading} onClick={() => searchItems(0)}>
          <Search />
        </IconButton>
        <Button disabled={loading} startIcon={<Download sx={{ mr: 1 }} />} onClick={() => exportItems()}>
          Exportieren
        </Button>
      </Toolbar>
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "80vh", mt: 3 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>EAN</TableCell>
                <TableCell>Palette</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell>Titel</TableCell>
                <TableCell>Preis</TableCell>
                <TableCell>Nutzer</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {offers.map((offer, index) => (
                <TableRow key={offer.id}>
                  <TableCell>{offer.item?.data.attributes.ean}</TableCell>
                  <TableCell>{offer.paletteId}</TableCell>
                  <TableCell>{offer.ascendingId}</TableCell>
                  <TableCell>
                    <Typography>{offer.item?.data.attributes.properties?.[0]?.title}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color={offer.price < PRICE_THRESHOLD ? "error" : "primary"}>
                      {offer.price.toFixed(2).replace(".", ",")} €
                    </Typography>
                  </TableCell>
                  <TableCell>{offer.user}</TableCell>
                  <TableCell>{offer.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default OverviewRoute;
