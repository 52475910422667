import { FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { CdType, PropertiesCd } from "./ItemHistory";
import { useMemo } from "react";

function EditCdProperties({
  properties,
  setProperties,
  validate,
}: {
  properties: Partial<PropertiesCd>;
  setProperties: (properties: Partial<PropertiesCd>) => void;
  validate?: boolean;
}) {
  const genres = useMemo(
    () => [
      "CD / Electronic",
      "CD",
      "Bravo Hits CDs",
      "CD / Blues",
      "CD / Classical",
      "CD / Funk/Soul",
      "CD / Hip Hop",
      "CD / Jazz",
      "CD / Pop",
      "CD / Reggae",
      "CD / Rock",
      "Stage & Screen",
      "Folk, World & Country",
    ],
    []
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          CD Einstellungen
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            size="small"
            onChange={(e) => setProperties({ ...properties, artist: e.target.value })}
            value={properties.artist}
          />
          <FormHelperText>Interpret</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth error={validate && !properties.title}>
          <TextField
            size="small"
            onChange={(e) => setProperties({ ...properties, title: e.target.value })}
            value={properties.title}
          />
          <FormHelperText>Titel</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth error={validate && !properties.cdType}>
          <Select
            value={properties.cdType}
            label="Typ"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, cdType: e.target.value as CdType })}
          >
            {Object.values(CdType).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Typ</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth error={validate && !properties.genre}>
          <Select
            value={properties.genre}
            label="Genre"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, genre: e.target.value })}
          >
            {genres.map((genre) => (
              <MenuItem key={genre} value={genre}>
                {genre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Genre</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default EditCdProperties;

// Benutzer Name eintragen mit Dropdown speichern
// +Palettennummer vor EAN scannen
// Bei < 5€ Preisen speichern ohne Daten
// Export mit Timestamp
// Sortierung nach neuestem Timestamp + Name anzeige
