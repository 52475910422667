import { Download } from "@mui/icons-material";
import {
  Button,
  Container,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import {
  ItemType,
  OfferAll,
  PropertiesAudiobook,
  PropertiesCd,
  PropertiesDvd,
  ScanItemAll,
} from "../components/ItemHistory";
import { STRAPI_TOKEN, STRAPI_URI } from "../constants";
import { useDownload } from "../hooks/useDownload";

function DataExport() {
  const download = useDownload("items", "csv");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<ItemType | "offer">(ItemType.CD);
  const [items, setItems] = useState<ScanItemAll[] | OfferAll[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [propertyColumns, setPropertyColumns] = useState<Record<string, string>>({});

  const searchItems = useCallback(
    async (resetToPage?: number) => {
      setLoading(true);
      if (resetToPage !== undefined) setPage(resetToPage);

      try {
        const query =
          type === "offer"
            ? `offers?populate[0]=item&populate[1]=item.properties&sort=id:desc`
            : `items?filters[type][$eq]=${type}&populate=properties&sort=id:desc`;
        const response = await fetch(
          `${STRAPI_URI}${query}&pagination[page]=${(resetToPage ?? page) + 1}&pagination[pageSize]=${rowsPerPage}`,
          { headers: { Authorization: `Bearer ${STRAPI_TOKEN}` } }
        );
        const { data, meta, error } = await response.json();

        if (error) throw new Error(error);

        setItems(data.map(({ id, attributes }: any) => ({ id, ...attributes })));
        setCount(meta.pagination.total);
      } catch (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      }

      setLoading(false);
    },
    [page, rowsPerPage, type]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportItems = useCallback(async () => {
    setLoading(true);

    const query =
      type === "offer"
        ? `offers?populate[0]=item&populate[1]=item.properties&sort=id:desc`
        : `items?filters[type][$eq]=${type}&populate=properties&sort=id:desc`;

    const offers: OfferAll[] = [];
    const items: ScanItemAll[] = [];
    let total = 0;
    let page = 1;

    try {
      do {
        const response = await fetch(`${STRAPI_URI}${query}&pagination[pageSize]=50&pagination[page]=${page}`, {
          headers: { Authorization: `Bearer ${STRAPI_TOKEN}` },
        });
        const { data, error, meta } = await response.json();
        if (error) throw new Error(error);

        if (type === "offer") {
          offers.push(...data.map(({ id, attributes }: any) => ({ id, ...attributes })));
        } else {
          items.push(...data.map(({ id, attributes }: any) => ({ id, ...attributes })));
        }
        total = meta.pagination.total;
        page++;
      } while ((type === "offer" ? offers.length : items.length) < total);

      const csv = [];
      if (type === "offer") {
        const header = ["EAN", "SKU", "Zustand", "Preis", "Palette", "Erstellt", "Aktualisiert", "Nutzer"];
        const body = offers.map((offer) => [
          offer.item?.data.attributes.ean,
          offer.ascendingId,
          `"${offer.condition}"`,
          offer.price.toFixed(2),
          offer.paletteId,
          offer.createdAt && `"${new Date(offer.createdAt).toLocaleString()}"`,
          offer.updatedAt && `"${new Date(offer.updatedAt).toLocaleString()}"`,
          `"${offer.user}"`,
        ]);
        csv.push(header, ...body);
      } else {
        const header = [
          "EAN",
          "Basispreis",
          "Bild",
          ...Object.values(propertyColumns),
          "Erstellt",
          "Aktualisiert",
          "Nutzer",
        ];
        const body = items.map((item) => [
          item.ean,
          item.price.toFixed(2),
          `"${item.image}"`,
          ...(Object.keys(propertyColumns) as (keyof (PropertiesCd | PropertiesDvd | PropertiesAudiobook))[]).map(
            (key) =>
              (key as string) === "price"
                ? parseFloat(item.properties?.[0]?.[key]!).toFixed(2)
                : typeof item.properties?.[0]?.[key] === "string"
                ? `"${item.properties?.[0]?.[key]}"`
                : item.properties?.[0]?.[key]
          ),
          item.createdAt && `"${new Date(item.createdAt).toLocaleString()}"`,
          item.updatedAt && `"${new Date(item.updatedAt).toLocaleString()}"`,
          `"${item.user}"`,
        ]);
        csv.push(header, ...body);
      }

      const date = new Date();
      const dateParts = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
      ];
      download(csv.join("\n"), `${type === "offer" ? "full_offer" : "full_item"}_export_${type}_${dateParts.join("")}`);
    } catch (error) {
      enqueueSnackbar("Fehler beim Exportieren der Artikel", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [download, type, propertyColumns]);

  useEffect(() => {
    searchItems();
  }, [page, searchItems, type]);

  useEffect(() => {
    switch (type) {
      case ItemType.CD:
        const columnsCD: Partial<Record<keyof PropertiesCd, string>> = {
          artist: "Künstler",
          title: "Titel",
          cdType: "Typ",
          genre: "Genre",
        };
        setPropertyColumns(columnsCD);
        break;
      case ItemType.DVD:
        const columnsDVD: Partial<Record<keyof PropertiesDvd, string>> = {
          title: "Titel",
          FSK: "FSK",
          dvdType: "Typ",
          format: "Format",
          language: "Sprache",
          genre: "Genre",
        };
        setPropertyColumns(columnsDVD);
        break;
      case ItemType.AUDIOBOOK:
        const columnsAudiobook: Partial<Record<keyof PropertiesAudiobook, string>> = {
          author: "Autor",
          title: "Titel",
          language: "Sprache",
          format: "Format",
          genre: "Genre",
        };
        setPropertyColumns(columnsAudiobook);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <Container maxWidth="xl" sx={{ my: 8 }}>
      <Paper sx={{ overflow: "hidden" }}>
        <Stack direction="row" sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={type}
            onChange={(event: React.SyntheticEvent, newType: ItemType | "offer") => setType(newType)}
            aria-label="lab API tabs example"
          >
            {Object.entries(ItemType).map(([key, value]) => (
              <Tab key={key} label={key} value={value} />
            ))}
            <Tab label="Angebote" value={"offer"} />
          </Tabs>
          <Button
            disabled={loading}
            startIcon={<Download sx={{ mr: 1 }} />}
            onClick={() => exportItems()}
            sx={{ ml: "auto" }}
          >
            Exportieren
          </Button>
        </Stack>
        <TableContainer sx={{ mt: 3 }}>
          <Table stickyHeader size="small">
            <TableHead>
              {type === "offer" ? (
                <TableRow>
                  <TableCell>EAN</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Zustand</TableCell>
                  <TableCell>Preis</TableCell>
                  <TableCell>Palette</TableCell>
                  <TableCell>Erstellt</TableCell>
                  <TableCell>Aktualisiert</TableCell>
                  <TableCell>Nutzer</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>EAN</TableCell>
                  <TableCell>Basispreis</TableCell>
                  <TableCell>Bild</TableCell>
                  {Object.entries(propertyColumns).map(([key, column]) => (
                    <TableCell key={key}>{column}</TableCell>
                  ))}
                  <TableCell>Erstellt</TableCell>
                  <TableCell>Aktualisiert</TableCell>
                  <TableCell>Nutzer</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {type === "offer"
                ? (items as OfferAll[]).map((item) => (
                    <TableRow>
                      <TableCell>{item.item?.data.attributes.ean}</TableCell>
                      <TableCell>{item.ascendingId}</TableCell>
                      <TableCell>{item.condition}</TableCell>
                      <TableCell>{item.price.toFixed(2)}</TableCell>
                      <TableCell>{item.paletteId}</TableCell>
                      <TableCell>{item.createdAt && new Date(item.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{item.updatedAt && new Date(item.updatedAt).toLocaleString()}</TableCell>
                      <TableCell>{item.user}</TableCell>
                    </TableRow>
                  ))
                : (items as ScanItemAll[]).map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.ean}</TableCell>
                      <TableCell>{item.price.toFixed(2)}</TableCell>
                      <TableCell>{item.image}</TableCell>
                      {(
                        Object.keys(propertyColumns) as (keyof (PropertiesCd | PropertiesDvd | PropertiesAudiobook))[]
                      ).map((key) => (
                        <TableCell key={key}>
                          {(key as string) === "price"
                            ? parseFloat(item.properties?.[0][key]!).toFixed(2)
                            : item.properties?.[0][key]}
                        </TableCell>
                      ))}
                      <TableCell>{item.createdAt && new Date(item.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{item.updatedAt && new Date(item.updatedAt).toLocaleString()}</TableCell>
                      <TableCell>{item.user}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default DataExport;
