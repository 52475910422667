import { useCallback } from "react";
import {
  ItemType,
  OfferAll,
  PropertiesAudiobook,
  PropertiesCd,
  PropertiesDvd,
  ScanItemAll,
} from "../components/ItemHistory";
import { PRICE_THRESHOLD } from "../constants";

export const useValidateItem = () => {
  const validateItem = useCallback(
    (
      item: ScanItemAll,
      properties: Partial<PropertiesCd | PropertiesDvd | PropertiesAudiobook>,
      offer: Partial<OfferAll>
    ) => {
      const isTrash = item.price < PRICE_THRESHOLD;
      if (!item.ean) return "EAN fehlt";

      switch (item.type) {
        case ItemType.CD:
          const cdProperties = properties as PropertiesCd;
          if ((properties?.title?.length ?? 0) > 80)
            return `Titel zu lang (${properties?.title?.length ?? 0} von 80 Zeichen)`;
          if (!isTrash) {
            if (!offer.price) return "Preis fehlt";
            if (!offer.ascendingId) return "ID fehlt";
            if (!offer.condition) return "Zustand fehlt";
            if (!cdProperties?.title) return "Titel fehlt";
            if (!cdProperties?.cdType) return "CD-Typ fehlt";
            if (!cdProperties?.genre) return "Genre fehlt";
          }
          return true;
        case ItemType.DVD:
        case ItemType.BLUERAY:
          const dvdProperties = properties as PropertiesDvd;
          if ((properties?.title?.length ?? 0) > 80)
            return `Titel zu lang (${properties?.title?.length ?? 0} von 80 Zeichen)`;
          if (!isTrash) {
            if (!offer.price) return "Preis fehlt";
            if (!offer.ascendingId) return "ID fehlt";
            if (!offer.condition) return "Zustand fehlt";
            if (!dvdProperties?.title) return "Titel fehlt";
            if (!dvdProperties?.dvdType) return "DVD-Typ fehlt";
            if (!dvdProperties?.genre) return "Genre fehlt";
            if (!dvdProperties?.language) return "Sprache fehlt";
            if (!dvdProperties?.format) return "Format fehlt";
            if (dvdProperties?.FSK === undefined) return "FSK fehlt";
          }

          return true;
        case ItemType.AUDIOBOOK:
          const audiobookProperties = properties as PropertiesAudiobook;
          if ((properties?.title?.length ?? 0) > 80)
            return `Titel zu lang (${properties?.title?.length ?? 0} von 80 Zeichen)`;
          if (!isTrash) {
            if (!offer.price) return "Preis fehlt";
            if (!offer.ascendingId) return "ID fehlt";
            if (!offer.condition) return "Zustand fehlt";
            if (!audiobookProperties?.title) return "Titel fehlt";
            if (!audiobookProperties?.language) return "Sprache fehlt";
            if (!audiobookProperties?.author) return "Autor fehlt";
            if (!audiobookProperties?.genre) return "Genre fehlt";
            if (!audiobookProperties?.format) return "Format fehlt";
          }

          return true;
      }
    },
    []
  );

  return validateItem;
};
