let filterHighlights: RegExp[] = [];
let loading: Promise<RegExp[]> | null = null;

export const useFilterHighlight = () => {
  const getFilterHighlights = async () => {
    if (loading || filterHighlights.length) return filterHighlights ?? (await loading);

    loading = new Promise(async (resolve, reject) => {
      const response = await fetch("/base/filter/highlight");
      const data = await response.json();
      filterHighlights = data.map((highlight: { match: string }) => new RegExp(highlight.match, "gi"));
      resolve(filterHighlights);
      console.log("filterHighlights", filterHighlights);
    });
  };

  return { getFilterHighlights };
};
