import { useEffect, useState } from "react";
import { useUserLogin } from "../hooks/useUserLogin";
import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import UserAvatar from "../components/UserAvatar";
import { BACKEND_V2_URI } from "../constants";

interface User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
  createdBy: Partial<User>;
  createdAt: string;
  stats: {
    level: number;
    experience: number;
    scannedItems: number;
  };
}

interface PagedResponse<T> {
  data: T[];
  page: number;
  pageSize: number;
  total: number;
}

function UserListRoute() {
  const { userName, userToken } = useUserLogin();
  const [users, setUsers] = useState<User[]>([]);
  const [creatingUser, setCreatingUser] = useState(true);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserRole, setNewUserRole] = useState<string>("USER");

  const fetchUsers = async () => {
    const response = await fetch(`${BACKEND_V2_URI}user`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    const data: PagedResponse<User> = await response.json();
    setUsers(data.data);
  };

  const createUser = async () => {
    const response = await fetch(`${BACKEND_V2_URI}user`, {
      method: "POST",
      headers: { Authorization: `Bearer ${userToken}`, "Content-Type": "application/json" },
      body: JSON.stringify({
        username: newUserName,
        email: newUserEmail,
        password: newUserPassword,
        roles: [newUserRole],
      }),
    });
    if (response.ok) {
      fetchUsers();
      setCreatingUser(false);
    }
  };

  const deleteUser = async (userId: number) => {
    const response = await fetch(`${BACKEND_V2_URI}user/${userId}`, {
      method: "DELETE",
      headers: { Authorization: `Bearer ${userToken}` },
    });
    if (response.ok) {
      fetchUsers();
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box sx={{ p: 2, maxWidth: "800px", margin: "auto" }}>
      <Stack direction="row" sx={{ mb: 2, alignItems: "center" }}>
        <h1>Benutzer</h1>
        <Stack direction="row" spacing={2} sx={{ ml: "auto" }}>
          <Button onClick={fetchUsers}>Aktualisieren</Button>
          <Button>Neuer Benutzer</Button>
        </Stack>
      </Stack>
      {creatingUser && (
        <Grid container spacing={2} sx={{ mb: 5 }}>
          <Grid item xs={4}>
            <TextField label="Benutzername" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} />
          </Grid>
          <Grid item xs={4}>
            <TextField label="E-Mail" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Passwort"
              type="password"
              value={newUserPassword}
              onChange={(e) => setNewUserPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="contained" color="primary" onClick={createUser}>
              Erstellen
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2} alignItems={"center"}>
        {users.map((user) => (
          <>
            <Grid item xs={4} key={user.id}>
              <UserAvatar
                userName={user.username}
                userAvatar={null}
                userExperience={user.stats.experience}
                userLevel={user.stats.level}
                borderStyle="rocket-league"
              />
            </Grid>
            <Grid item xs={3}>
              {user.roles.join(", ")}
            </Grid>
            <Grid item xs={3}>
              {user.stats.scannedItems} gescannte Artikel
            </Grid>
            <Grid item xs={2}>
              <Button color="error" disabled={user.username === userName} onClick={() => deleteUser(user.id)}>
                Löschen
              </Button>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default UserListRoute;
