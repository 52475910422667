import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import EditItemPrice from "../components/EditItemPrice";
import ItemHistory, {
  DvdFormat,
  ItemType,
  OfferAll,
  PropertiesAudiobook,
  PropertiesCd,
  PropertiesDvd,
  ScanItemAll,
} from "../components/ItemHistory";
import ScanForm, { ScanFormRef } from "../components/ScanForm";
import {
  BACKEND_V2_URI,
  EBAY_FILTER,
  EBAY_FILTER_SOLD,
  HISTORY_SIZE,
  PRICE_THRESHOLD,
  STRAPI_TOKEN,
  STRAPI_URI,
} from "../constants";
import { useRedirect } from "../hooks/useRedirect";
import { useUserLogin } from "../hooks/useUserLogin";

function ScanEanRoute() {
  const { userId, userToken, reloadStats } = useUserLogin();
  const theme = useTheme();
  const { redirectUrl } = useRedirect();
  const [type, setType] = useState<ItemType>(ItemType.CD);
  const [paletteId, setPaletteId] = useState("");
  const [user, _setUser] = useState<string>(localStorage.getItem("lastUser") ?? "");
  const [users, _setUsers] = useState<string[]>(JSON.parse(localStorage.getItem("userList") ?? "[]"));
  const [currentItem, setCurrentItem] = useState<ScanItemAll | null>(null);
  const [history, setHistory] = useState<ScanItemAll[]>([]);
  const [loading, setLoading] = useState(false);
  const updateOpenedEan = useState("")[1];
  const scanRef = useRef<ScanFormRef | null>(null);

  const setUser = useCallback((user: string) => {
    localStorage.setItem("lastUser", user);
    _setUser(user);
  }, []);

  const setUsers = useCallback((users: string[]) => {
    localStorage.setItem("userList", JSON.stringify(users));
    _setUsers(users);
  }, []);

  const filterUser = createFilterOptions<{ user: string; inputValue?: string }>();

  useEffect(() => {
    setCurrentItem(null);
  }, [type]);

  useEffect(() => {
    const windows = [
      window.open(redirectUrl("https://www.ebay.de"), "amazon"),
      window.open(redirectUrl("https://www.ebay.de"), "ebay"),
      window.open(redirectUrl("https://www.ebay.de"), "ebay-sold"),
    ];
    windows.forEach((w) => w?.blur());
  }, [redirectUrl]);

  useEffect(() => {
    if (currentItem?.ean) {
      updateOpenedEan((ean) => {
        if (ean !== currentItem.ean) {
          const windows = [
            window.open(redirectUrl(`https://www.amazon.de/s?k=${currentItem.ean}`), "amazon"),
            window.open(redirectUrl(`https://www.ebay.de/sch/i.html?${EBAY_FILTER}&_nkw=(${currentItem.ean})`), "ebay"),
            window.open(
              redirectUrl(`https://www.ebay.de/sch/i.html?${EBAY_FILTER_SOLD}&_nkw=(${currentItem.ean})`),
              "ebay-sold"
            ),
          ];
          windows.forEach((w) => w?.blur());
        }

        return currentItem.ean;
      });
    }
  }, [currentItem, redirectUrl, updateOpenedEan]);

  const logEan = useCallback(async (ean: string, price: number, color: string) => {
    try {
      const response = await fetch(`${STRAPI_URI}scan-logs`, {
        method: "POST",
        headers: { Authorization: `Bearer ${STRAPI_TOKEN}`, "Content-Type": "application/json" },
        body: JSON.stringify({ data: { ean, user } }),
      });

      if (!response.ok) enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    }

    try {
      const response = await fetch(`${BACKEND_V2_URI}log/user/scan`, {
        method: "POST",
        headers: { Authorization: `Bearer ${userToken}`, "Content-Type": "application/json" },
        body: JSON.stringify({ userId, productId: ean, paletteId, price, color }),
      });

      if (!response.ok) enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
      else reloadStats();
    } catch (error) {
      enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    }
  }, [paletteId, reloadStats, user, userId, userToken]);

  const fetchItem = useCallback(
    async (ean: string) => {
      if (!ean) return;

      setLoading(true);

      try {
        const response = await fetch(`${STRAPI_URI}items?populate=properties&filters[ean][$eq]=${ean}`, {
          headers: { Authorization: `Bearer ${STRAPI_TOKEN}` },
        });
        const { data, error } = await response.json();

        if (error) {
          enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
        } else if (data.length) {
          const { id, attributes } = data[0];
          setCurrentItem({ id, ...attributes, type, saved: true });

          const color = attributes?.highlight
            ? typeof attributes.highlight === "string"
              ? attributes.highlight
              : "blue"
            : currentItem?.price && currentItem.price < PRICE_THRESHOLD
            ? "red"
            : id
            ? "yellow"
            : "none";
          logEan(ean, attributes.price, color);
        } else {
          // Get item from keepa
          try {
            const response = await fetch(`/base/item/search/${ean}`);
            const {
              products: [result],
            } = await response.json();
            const {
              title,
              author = "",
              imagesCSV,
              stats: {
                current: [, , usedPrice],
              },
            } = result;
            const [image] = imagesCSV.split(",");
            // @ts-ignore - for testing purposes
            setCurrentItem({
              ean,
              // @ts-ignore - for testing purposes
              type,
              price: Math.max(0, Math.floor(usedPrice / 100)),
              image: `https://images-na.ssl-images-amazon.com/images/I/${image}`,
              // @ts-ignore - for testing purposes
              properties: [
                {
                  title,
                  artist: author,
                  // @ts-ignore - for testing purposes
                  format:
                    type === ItemType.DVD ? DvdFormat.DVD : type === ItemType.BLUERAY ? DvdFormat.BLURAY : undefined,
                },
              ],
            });

            const price = Math.max(0, Math.floor(usedPrice / 100));
            const color = price >= PRICE_THRESHOLD ? "green" : "red";
            logEan(ean, price, color);
          } catch (error) {
            setCurrentItem({ ean, price: 0, type });
            logEan(ean, 0, "green");
          }
        }
      } catch (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      }

      setLoading(false);
    },
    [currentItem, logEan, type]
  );

  const updateItem = useCallback(
    (item: ScanItemAll) => {
      setCurrentItem(item);
      setHistory(history.map((i: ScanItemAll) => (i.ean === item.ean ? ({ ...i, ...item } as ScanItemAll) : i)));
    },
    [history]
  );

  const saveItem = useCallback(
    async (
      item: ScanItemAll,
      properties?: Partial<PropertiesCd | PropertiesDvd | PropertiesAudiobook>,
      offer?: OfferAll
    ) => {
      item = { ...item, price: Math.floor(item.price) + 0.99 };
      const propertyArray = [];
      if (properties) {
        switch (item.type) {
          case ItemType.CD:
            properties.__component = "item-types.cd-properties";
            break;
          case ItemType.DVD:
            properties.__component = "item-types.dvd-properties";
            break;
          case ItemType.BLUERAY:
            properties.__component = "item-types.dvd-properties";
            break;
          case ItemType.AUDIOBOOK:
            properties.__component = "item-types.audiobook-properties";
            break;
        }
        propertyArray.push(properties);
      }

      if (item.id) {
        try {
          const response = await fetch(`${STRAPI_URI}items/${item.id}`, {
            method: "PUT",
            headers: { Authorization: `Bearer ${STRAPI_TOKEN}`, "Content-Type": "application/json" },
            body: JSON.stringify({
              data: {
                id: item.id,
                price: item.price,
                type: item.type,
                image: item.image,
                highlight: item.highlight === true,
                properties: propertyArray,
              },
            }),
          });

          if (response.ok) updateItem({ ...item, saved: true });
          else enqueueSnackbar("Fehler beim Speichern des Artikels", { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Fehler beim Speichern des Artikels", { variant: "error" });
          return;
        }
      } else {
        try {
          const response = await fetch(`${STRAPI_URI}items`, {
            method: "POST",
            headers: { Authorization: `Bearer ${STRAPI_TOKEN}`, "Content-Type": "application/json" },
            body: JSON.stringify({
              data: {
                ean: item.ean,
                price: item.price,
                type: item.type,
                image: item.image,
                highlight: item.highlight === true,
                properties: propertyArray,
                user,
              },
            }),
          });
          const { data } = await response.json();

          item.id = data.id;
          if (response.ok) updateItem({ ...item, saved: true });
          else enqueueSnackbar("Fehler beim Speichern des Artikels", { variant: "error" });
        } catch (error) {
          enqueueSnackbar("Fehler beim Speichern des Artikels", { variant: "error" });
          return;
        }
      }

      if (offer) {
        const response = await fetch(`${STRAPI_URI}offers`, {
          method: "POST",
          headers: { Authorization: `Bearer ${STRAPI_TOKEN}`, "Content-Type": "application/json" },
          body: JSON.stringify({ data: { ...offer, item: item.id, paletteId, user } }),
        });

        if (!response.ok) enqueueSnackbar("Fehler beim Speichern des Angebots", { variant: "error" });
      }

      const newHistory = [item, ...history];
      if (newHistory.length > HISTORY_SIZE) newHistory.pop();
      setHistory(newHistory);
      setCurrentItem(null);
      scanRef.current?.reset();
    },
    [history, paletteId, updateItem, user]
  );

  return (
    <Box
      sx={{
        minHeight: "100%",
        background: currentItem?.highlight
          ? typeof currentItem.highlight === "string"
            ? theme.palette[currentItem.highlight].light
            : theme.palette.info.light
          : currentItem?.price && currentItem.price < PRICE_THRESHOLD
          ? theme.palette.error.light
          : currentItem?.id
          ? theme.palette.warning.light
          : "",
      }}
    >
      <Container maxWidth="xl" sx={{ pt: 3 }}>
        <Stack direction="row" spacing={1} justifyContent="end">
          <FormControl sx={{ minWidth: "240px" }}>
            <Select
              value={type}
              label="Typ"
              displayEmpty
              size="small"
              onChange={(e) => setType(e.target.value as ItemType)}
            >
              {Object.values(ItemType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Typ</FormHelperText>
          </FormControl>
          <FormControl sx={{ minWidth: "240px" }}>
            <Autocomplete
              value={{ user }}
              options={users.map<{ user: string; inputValue?: string }>((user) => ({ user }))}
              size="small"
              freeSolo
              filterOptions={(options, params) => {
                const filtered = filterUser(options, params);

                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.user);
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    user: `"${inputValue}" hinzufügen`,
                  });
                }

                return filtered;
              }}
              onChange={(event, user) => {
                if (typeof user === "string") {
                  setUser(user);
                } else if (user && user.inputValue) {
                  // Create a new value from the user input
                  setUser(user.inputValue);
                  setUsers([...users, user.inputValue]);
                } else if (user) {
                  setUser(user.user);
                }
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                } else {
                  return option.user;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nutzer"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <FormHelperText>Nutzer</FormHelperText>
          </FormControl>
        </Stack>
      </Container>
      <Container
        sx={{
          paddingTop: 8,
          height: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          spacing={5}
        >
          <ScanForm
            ref={scanRef}
            loading={loading}
            onScan={(ean) => {
              fetchItem(ean);
            }}
            paletteId={paletteId}
            setPaletteId={setPaletteId}
          />
          {currentItem && !loading && (
            <EditItemPrice
              item={currentItem}
              onItemUpdate={saveItem}
              onHighlight={(highlight: boolean) => setCurrentItem({ ...currentItem, highlight: highlight })}
            />
          )}
          <ItemHistory items={history} />
        </Stack>
      </Container>
    </Box>
  );
}

export default ScanEanRoute;
