import {
  Autocomplete,
  Box,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { ItemType, ScanItemAll } from "../components/ItemHistory";
import ScanForm, { ScanFormRef } from "../components/ScanForm";
import { PRICE_THRESHOLD, STRAPI_TOKEN, STRAPI_URI } from "../constants";
import { useFilterHighlight } from "../hooks/useFilterHighlight";

function ScanKeepaEanRoute() {
  const theme = useTheme();
  const { getFilterHighlights } = useFilterHighlight();
  const [type, setType] = useState<ItemType>(ItemType.CD);
  const [paletteId, setPaletteId] = useState("");
  const [user, _setUser] = useState<string>(localStorage.getItem("lastUser") ?? "");
  const [users, _setUsers] = useState<string[]>(JSON.parse(localStorage.getItem("userList") ?? "[]"));
  const [currentItem, setCurrentItem] = useState<ScanItemAll | null>(null);
  const [loading, setLoading] = useState(false);
  const scanRef = useRef<ScanFormRef | null>(null);

  const setUser = useCallback((user: string) => {
    localStorage.setItem("lastUser", user);
    _setUser(user);
  }, []);

  const setUsers = useCallback((users: string[]) => {
    localStorage.setItem("userList", JSON.stringify(users));
    _setUsers(users);
  }, []);

  const filterUser = createFilterOptions<{ user: string; inputValue?: string }>();

  useEffect(() => {
    setCurrentItem(null);
  }, [type]);

  const fetchItem = async (ean: string) => {
    if (!ean) return;

    setLoading(true);

    try {
      const highlights = await getFilterHighlights();
      const response = await fetch(`${STRAPI_URI}items?populate=properties&filters[ean][$eq]=${ean}`, {
        headers: { Authorization: `Bearer ${STRAPI_TOKEN}` },
      });
      const { data, error } = await response.json();

      if (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      } else if (data.length) {
        const { id, attributes } = data[0];
        const highlight = highlights?.some((r) => r.test(attributes.properties.title));
        setCurrentItem({ id, ...attributes, type: attributes.type ?? type, saved: true, highlight });
      } else {
        // Get item from keepa
        try {
          const highlights = await getFilterHighlights();
          const response = await fetch(`/base/item/search/${ean}`);
          const {
            products: [result],
          } = await response.json();
          const {
            title,
            author = "",
            contributors,
            imagesCSV,
            stats: {
              current: [, , usedPrice],
            },
          } = result;
          const [image] = imagesCSV.split(",");
          const artists: string[] = contributors
            .filter(([, type]: [string, string]) => type === "artist")
            .map(([name]: [string, string]) => name);
          // @ts-ignore - for testing purposes
          setCurrentItem({
            ean,
            // @ts-ignore - for testing purposes
            type,
            price: Math.max(0, Math.floor(usedPrice / 100)),
            image: `https://images-na.ssl-images-amazon.com/images/I/${image}`,
            highlight:
              usedPrice >= 200 || usedPrice < 0
                ? "success"
                : highlights?.some((r) => r.test(title) || r.test(author) || artists.some((a) => r.test(a)))
                ? "info"
                : "error",
            // @ts-ignore - for testing purposes
            properties: [{ title, artist: author }],
          });
        } catch (error) {
          setCurrentItem({ ean, price: 0, type, highlight: "success" });
        }
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
    }

    setLoading(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        background: currentItem?.highlight
          ? typeof currentItem.highlight === "string"
            ? theme.palette[currentItem.highlight].light
            : theme.palette.info.light
          : currentItem?.price && currentItem.price < PRICE_THRESHOLD
          ? theme.palette.error.light
          : currentItem
          ? theme.palette.success.light
          : undefined,
      }}
    >
      <Container maxWidth="xl" sx={{ pt: 3 }}>
        <Stack direction="row" spacing={1} justifyContent="end">
          <FormControl sx={{ minWidth: "240px" }}>
            <Select
              value={type}
              label="Typ"
              displayEmpty
              size="small"
              onChange={(e) => setType(e.target.value as ItemType)}
            >
              {Object.values(ItemType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Typ</FormHelperText>
          </FormControl>
          <FormControl sx={{ minWidth: "240px" }}>
            <Autocomplete
              value={{ user }}
              options={users.map<{ user: string; inputValue?: string }>((user) => ({ user }))}
              size="small"
              freeSolo
              filterOptions={(options, params) => {
                const filtered = filterUser(options, params);

                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.user);
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    user: `"${inputValue}" hinzufügen`,
                  });
                }

                return filtered;
              }}
              onChange={(event, user) => {
                if (typeof user === "string") {
                  setUser(user);
                } else if (user && user.inputValue) {
                  // Create a new value from the user input
                  setUser(user.inputValue);
                  setUsers([...users, user.inputValue]);
                } else if (user) {
                  setUser(user.user);
                }
              }}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                } else {
                  return option.user;
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nutzer"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <FormHelperText>Nutzer</FormHelperText>
          </FormControl>
        </Stack>
      </Container>
      <Container
        sx={{
          paddingTop: 8,
          height: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          spacing={5}
        >
          <ScanForm
            ref={scanRef}
            loading={loading}
            onScan={fetchItem}
            paletteId={paletteId}
            setPaletteId={setPaletteId}
          />
          <Grid container justifyContent="center" spacing={1} mb={3} maxWidth="sm">
            <Grid item xs={12} mb={3}>
              <Typography variant="h6" align="center">
                Ergebnis
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  size="small"
                  disabled
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ fontSize: "0.7em", mr: 1 }}>.99</Typography>
                        <Chip size="small" label="€" />
                      </InputAdornment>
                    ),
                  }}
                  value={currentItem?.price ?? ""}
                />
                <FormHelperText>Preis</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: "center" }}>
              <FormControlLabel
                control={<Switch checked={currentItem?.highlight === true} disabled />}
                label="Sammeln"
              />
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default ScanKeepaEanRoute;
