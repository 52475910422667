import { useEffect, useState } from "react";

export interface UserAvatarBorderProps {
  type: "rocket-league" | "us-army" | "league-of-legends";
  level: number;
}

const avatarBordersRocketLeague = {
  2: "/avatar-borders/rocket-league/Default_avatar_border_icon.png",
  3: "/avatar-borders/rocket-league/Lvl25_avatar_border_icon.png",
  4: "/avatar-borders/rocket-league/Lvl50_avatar_border_icon.png",
  5: "/avatar-borders/rocket-league/Lvl75_avatar_border_icon.png",
  6: "/avatar-borders/rocket-league/Lvl100_avatar_border_icon.png",
  7: "/avatar-borders/rocket-league/Lvl150_avatar_border_icon.png",
  8: "/avatar-borders/rocket-league/Lvl200_avatar_border_icon.png",
  9: "/avatar-borders/rocket-league/Lvl250_avatar_border_icon.png",
  10: "/avatar-borders/rocket-league/Lvl300_avatar_border_icon.png",
  12: "/avatar-borders/rocket-league/Lvl350_avatar_border_icon.png",
  24: "/avatar-borders/rocket-league/Lvl400_avatar_border_icon.png",
  16: "/avatar-borders/rocket-league/Lvl450_avatar_border_icon.png",
  18: "/avatar-borders/rocket-league/Lvl500_avatar_border_icon.png",
  20: "/avatar-borders/rocket-league/Lvl550_avatar_border_icon.png",
  25: "/avatar-borders/rocket-league/Lvl600_avatar_border_icon.png",
  30: "/avatar-borders/rocket-league/Lvl650_avatar_border_icon.png",
  35: "/avatar-borders/rocket-league/Lvl700_avatar_border_icon.png",
  40: "/avatar-borders/rocket-league/Lvl750_avatar_border_icon.png",
  45: "/avatar-borders/rocket-league/Lvl800_avatar_border_icon.png",
  50: "/avatar-borders/rocket-league/Lvl850_avatar_border_icon.png",
  55: "/avatar-borders/rocket-league/Lvl900_avatar_border_icon.png",
  60: "/avatar-borders/rocket-league/Lvl950_avatar_border_icon.png",
  65: "/avatar-borders/rocket-league/Lvl1000_avatar_border_icon.png",
  70: "/avatar-borders/rocket-league/Lvl1050_avatar_border_icon.png",
  75: "/avatar-borders/rocket-league/Lvl1100_avatar_border_icon.png",
  80: "/avatar-borders/rocket-league/Lvl1150_avatar_border_icon.png",
  85: "/avatar-borders/rocket-league/Lvl1200_avatar_border_icon.png",
  90: "/avatar-borders/rocket-league/Lvl1250_avatar_border_icon.png",
  95: "/avatar-borders/rocket-league/Lvl1350_avatar_border_icon.png",
  100: "/avatar-borders/rocket-league/Lvl1400_avatar_border_icon.png",
};

const avatarBordersUsArmy = {
  2: "/avatar-borders/us-army/E2-private-second-class.png",
  3: "/avatar-borders/us-army/E3-private-first-class.png",
  4: "/avatar-borders/us-army/E4-corporal.png",
  5: "/avatar-borders/us-army/E4-specialist.png",
  10: "/avatar-borders/us-army/E5-sergeant.png",
  12: "/avatar-borders/us-army/E6-staff-sergeant.png",
  14: "/avatar-borders/us-army/E7-sergeant-first-class.png",
  16: "/avatar-borders/us-army/E8-master-sergeant.png",
  18: "/avatar-borders/us-army/E8b-first-sergeant.png",
  20: "/avatar-borders/us-army/E9-sergeant-major.png",
  25: "/avatar-borders/us-army/E9b-command-sergeant-major.png",
  30: "/avatar-borders/us-army/E9c-sergeant-major-of-the-army.png",
  35: "/avatar-borders/us-army/W1-warrant-officer-1.png",
  40: "/avatar-borders/us-army/W2-chief-warrant-officer-2.png",
  45: "/avatar-borders/us-army/W3-chief-warrant-officer-3.png",
  50: "/avatar-borders/us-army/W4-chief-warrant-officer-4.png",
  55: "/avatar-borders/us-army/W5-chief-warrant-officer-5.png",
  60: "/avatar-borders/us-army/O1-second-lieutenant.png",
  65: "/avatar-borders/us-army/O2-first-lieutenant.png",
  70: "/avatar-borders/us-army/O3-captain.png",
  75: "/avatar-borders/us-army/O4-major.png",
  80: "/avatar-borders/us-army/O5-lieutenant-colonel.png",
  85: "/avatar-borders/us-army/O6-colonel.png",
  90: "/avatar-borders/us-army/O7-Brigadier-General.png",
  95: "/avatar-borders/us-army/O8-Major-General.png",
  100: "/avatar-borders/us-army/O9-Lieutenant-General.png",
};

const avatarBordersLeagueOfLegends = {
  2: "/avatar-borders/league-of-legends/Level_1_Summoner_Icon_Border.png",
  5: "/avatar-borders/league-of-legends/Level_100_Summoner_Icon_Border.png",
  10: "/avatar-borders/league-of-legends/Level_150_Summoner_Icon_Border.png",
  15: "/avatar-borders/league-of-legends/Level_200_Summoner_Icon_Border.png",
  20: "/avatar-borders/league-of-legends/Level_225_Summoner_Icon_Border.png",
  25: "/avatar-borders/league-of-legends/Level_250_Summoner_Icon_Border.png",
  30: "/avatar-borders/league-of-legends/Level_275_Summoner_Icon_Border.png",
  35: "/avatar-borders/league-of-legends/Level_300_Summoner_Icon_Border.png",
  40: "/avatar-borders/league-of-legends/Level_325_Summoner_Icon_Border.png",
  50: "/avatar-borders/league-of-legends/Level_350_Summoner_Icon_Border.png",
  60: "/avatar-borders/league-of-legends/Level_375_Summoner_Icon_Border.png",
  70: "/avatar-borders/league-of-legends/Level_400_Summoner_Icon_Border.png",
  80: "/avatar-borders/league-of-legends/Level_425_Summoner_Icon_Border.png",
  90: "/avatar-borders/league-of-legends/Level_450_Summoner_Icon_Border.png",
  100: "/avatar-borders/league-of-legends/Level_475_Summoner_Icon_Border.png",
};

const UserAvatarBorder = ({ level, type }: UserAvatarBorderProps) => {
  const [border, setBorder] = useState("");

  function getAvatarBorderStyle(): React.CSSProperties {
    switch (type) {
      case "rocket-league":
        return { width: "100%", height: "100%", position: "absolute", top: 0, left: 0 };
      case "us-army":
        return {
          height: "25px",
          position: "absolute",
          bottom: 0,
          right: "12.5px",
          transform: "translateX(50%)",
          filter: "drop-shadow(0 0 5px black)",
        };
      case "league-of-legends":
        return { width: "164%", height: "166%", position: "absolute", top: "-28%", left: "-31%" };
    }
  }

  useEffect(() => {
    switch (type) {
      case "rocket-league":
        setBorder(
          Object.entries(avatarBordersRocketLeague)
            .sort(([a], [b]) => parseInt(b) - parseInt(a))
            .find(([key]) => level >= parseInt(key))?.[1] ?? ""
        );
        break;
      case "us-army":
        setBorder(
          Object.entries(avatarBordersUsArmy)
            .sort(([a], [b]) => parseInt(b) - parseInt(a))
            .find(([key]) => level >= parseInt(key))?.[1] ?? ""
        );
        break;
      case "league-of-legends":
        setBorder(
          Object.entries(avatarBordersLeagueOfLegends)
            .sort(([a], [b]) => parseInt(b) - parseInt(a))
            .find(([key]) => level >= parseInt(key))?.[1] ?? ""
        );
        break;
    }
  }, [level, type]);

  return (
    <div
      style={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        margin: "-10px",
        pointerEvents: "none",
      }}
    >
      {border && <img src={border} alt="Avatar border" style={getAvatarBorderStyle()} />}
    </div>
  );
};

export default UserAvatarBorder;
