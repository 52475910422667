import { useEffect } from "react";
import { useParams } from "react-router-dom";

function RedirectMarketPlaceRoute() {
  const { redirectUrl = window.location.href } = useParams();

  // redirect to redirectUri
  useEffect(() => {
    window.location.href = redirectUrl;
  }, [redirectUrl]);

  return <></>;
}

export default RedirectMarketPlaceRoute;
