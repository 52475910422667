import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import ScanEanRoute from "./routes/ScanEanRoute";
import { SnackbarProvider } from "notistack";
import OverviewRoute from "./routes/OverviewRoute";
import RedirectMarketPlaceRoute from "./routes/RedirectMarketPlaceRoute";
import DataExport from "./routes/DataExport";
import ScanKeepaEanRoute from "./routes/ScanKeepaEanRoute";
import ScanKeepaEanRouteV2 from "./routes/ScanKeepaEanRouteV2";
import LayoutV2 from "./layout/LayoutV2";
import LoginV2Route from "./routes/LoginV2Route";
import { UserLoginProvider } from "./hooks/useUserLogin";
import UserListRoute from "./routes/UserListRoute";
import DashboardRoute from "./routes/DashboardRoute";
import ScanLogRoute from "./routes/ScanLogRoute";

function App() {
  return (
    <UserLoginProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ScanEanRoute />} />
          <Route path="scan-alpha" element={<ScanKeepaEanRoute />} />
          <Route path="overview" element={<OverviewRoute />} />
          <Route path="overview/export-all" element={<DataExport />} />
          <Route path="redirect/:redirectUrl" element={<RedirectMarketPlaceRoute />} />
        </Route>
        <Route path="/v2/" element={<LayoutV2 />}>
          <Route path="login" element={<LoginV2Route />} />
          <Route path="scan" element={<ScanEanRoute />} />
          <Route index element={<DashboardRoute />} />
          <Route path="scan-alpha" element={<ScanKeepaEanRouteV2 />} />
          <Route path="overview" element={<OverviewRoute />} />
          <Route path="overview/export-all" element={<DataExport />} />
          <Route path="redirect/:redirectUrl" element={<RedirectMarketPlaceRoute />} />
          <Route path="users" element={<UserListRoute />} />
          <Route path="logs" element={<ScanLogRoute />} />
        </Route>
      </Routes>
      <SnackbarProvider />
    </UserLoginProvider>
  );
}

export default App;
