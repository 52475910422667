import { Box, Stack } from "@mui/material";
import Footer from "./Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderV2 from "./HeaderV2";
import { useUserLogin } from "../hooks/useUserLogin";
import { useEffect } from "react";

function LayoutV2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userName } = useUserLogin();

  useEffect(() => {
    if (location.pathname !== "/v2/login" && !userName) {
      navigate("/v2/login");
    }
  }, [userName, location, navigate]);

  return (
    <Stack sx={{ height: "100%" }}>
      <HeaderV2 />
      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
      <Footer />
    </Stack>
  );
}

export default LayoutV2;
