import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { useUserLogin } from "../hooks/useUserLogin";
import { useNavigate } from "react-router-dom";

function LoginV2Route() {
  const { login } = useUserLogin();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const performLogin = () => {
    login(username, password).then(() => navigate("/v2"));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>Login</h1>
      <Grid container spacing={2} sx={{ maxWidth: "320px" }}>
        <Grid item xs={12}>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant="contained" color="primary" onClick={performLogin}>
            Login
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginV2Route;
