import { useEffect, useState } from "react";
import { useUserLogin } from "../hooks/useUserLogin";
import { Box, Stack } from "@mui/material";
import UserAvatar from "../components/UserAvatar";

function DashboardRoute() {
  const { userName, userAvatar, userBorderStyle, userExperience, userLevel, userProgress, userProgressDescription } =
    useUserLogin();

  const [time, setTime] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().getHours());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (time < 6) {
      setGreeting("Nacht");
    } else if (time < 12) {
      setGreeting("Morgen");
    } else if (time < 18) {
      setGreeting("Tag");
    } else {
      setGreeting("Abend");
    }
  }, [time]);

  return (
    <Box sx={{ p: 2, height: "100%", maxWidth: "800px", margin: "auto" }}>
      <Stack spacing={2} direction="column" justifyContent="center" sx={{ height: "100%" }}>
        <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
          Guten {greeting}
          {userName ? ", " + userName : ""}!
        </h1>
        {userName && (
          <UserAvatar
            userName={userName}
            userAvatar={userAvatar}
            userExperience={userExperience}
            userLevel={userLevel}
            userProgress={userProgress}
            userProgressDescription={userProgressDescription}
            borderStyle={userBorderStyle}
            showProgress={true}
            dark={true}
          />
        )}
      </Stack>
    </Box>
  );
}

export default DashboardRoute;
