import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import { Refresh } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { DvdFormat, ItemType, ScanItemAll } from "../components/ItemHistory";
import { ScanFormRef } from "../components/ScanForm";
import { BACKEND_V2_URI, PRICE_THRESHOLD, STRAPI_TOKEN, STRAPI_URI } from "../constants";
import { useUserLogin } from "../hooks/useUserLogin";
import ScanFormSimplified from "../components/ScanFormSimplified";

function ScanKeepaEanRouteV2() {
  const { userToken, userName, userId, reloadStats } = useUserLogin();
  const theme = useTheme();
  const [type, setType] = useState<ItemType>(ItemType.CD);
  const [paletteId, setPaletteId] = useState("");
  const [currentItem, setCurrentItem] = useState<ScanItemAll | null>(null);
  const [loading, setLoading] = useState(false);
  const [allowScan, setAllowScan] = useState(false);
  const scanRef = useRef<ScanFormRef | null>(null);

  useEffect(() => {
    setCurrentItem(null);
  }, [type]);

  const logEan = useCallback(async (ean: string, price: number, color: string) => {
    try {
      const response = await fetch(`${STRAPI_URI}scan-logs`, {
        method: "POST",
        headers: { Authorization: `Bearer ${STRAPI_TOKEN}`, "Content-Type": "application/json" },
        body: JSON.stringify({ data: { ean, user: userName } }),
      });

      if (!response.ok) enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    } catch (error) {
      enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    }

    try {
      const response = await fetch(`${BACKEND_V2_URI}log/user/scan`, {
        method: "POST",
        headers: { Authorization: `Bearer ${userToken}`, "Content-Type": "application/json" },
        body: JSON.stringify({ userId, productId: ean, paletteId, price, color }),
      });

      if (!response.ok) enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
      else reloadStats();
    } catch (error) {
      enqueueSnackbar("Fehler beim Speichern des Scans", { variant: "error" });
    }
  }, [paletteId, reloadStats, userId, userName, userToken]);

  const fetchItem = useCallback(
    async (ean: string) => {
      if (!ean) return;

      setLoading(true);

      try {
        const response = await fetch(`${STRAPI_URI}items?populate=properties&filters[ean][$eq]=${ean}`, {
          headers: { Authorization: `Bearer ${STRAPI_TOKEN}` },
        });
        const { data, error } = await response.json();

        if (error) {
          enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
        } else if (data.length) {
          const { id, attributes } = data[0];
          setCurrentItem({ id, ...attributes, type, saved: true });

          const color = attributes?.highlight
            ? typeof attributes.highlight === "string"
              ? attributes.highlight
              : "blue"
            : currentItem?.price && currentItem.price < PRICE_THRESHOLD
            ? "red"
            : "yellow";
          logEan(ean, attributes.price, color);
        } else {
          // Get item from keepa
          try {
            const response = await fetch(`/base/item/search/${ean}`);
            const {
              products: [result],
            } = await response.json();
            const {
              title,
              author = "",
              imagesCSV,
              stats: {
                current: [, , usedPrice],
              },
            } = result;
            const [image] = imagesCSV.split(",");
            // @ts-ignore - for testing purposes
            setCurrentItem({
              ean,
              // @ts-ignore - for testing purposes
              type,
              price: Math.max(0, Math.floor(usedPrice / 100)),
              image: `https://images-na.ssl-images-amazon.com/images/I/${image}`,
              // @ts-ignore - for testing purposes
              properties: [
                {
                  title,
                  artist: author,
                  // @ts-ignore - for testing purposes
                  format:
                    type === ItemType.DVD ? DvdFormat.DVD : type === ItemType.BLUERAY ? DvdFormat.BLURAY : undefined,
                },
              ],
            });

            const price = Math.max(0, Math.floor(usedPrice / 100));
            const color = price >= PRICE_THRESHOLD ? "green" : "red";
            logEan(ean, price, color);
          } catch (error) {
            setCurrentItem({ ean, price: 0, type, highlight: "success" });
            logEan(ean, 0, "green");
          }

          scanRef.current?.reset();
        }
      } catch (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      }

      setLoading(false);
    },
    [type, logEan, currentItem]
  );

  return (
    <Stack
      sx={{
        minHeight: "100%",
        background: currentItem?.highlight
          ? typeof currentItem.highlight === "string"
            ? theme.palette[currentItem.highlight].light
            : theme.palette.info.light
          : currentItem?.price && currentItem.price < PRICE_THRESHOLD
          ? theme.palette.error.light
          : currentItem?.id
          ? theme.palette.warning.light
          : theme.palette.success.light,
      }}
    >
      {allowScan && (
        <Container maxWidth="md" sx={{ mt: 3, mb: "auto" }}>
          <Stack direction="row" spacing={3} justifyContent="end" alignItems="center">
            <div>Palette {paletteId}</div>
            <div>Typ {type}</div>
            <Button variant="text" color="secondary" onClick={() => setAllowScan(false)} sx={{ px: 1, minWidth: 0 }}>
              <Refresh />
            </Button>
          </Stack>
        </Container>
      )}
      {!allowScan && (
        <Stack
          direction="row"
          mt="auto"
          mb="auto"
          spacing={1}
          justifyContent="center"
          alignItems="start"
          sx={{ maxWidth: "md" }}
        >
          <FormControl sx={{ minWidth: "240px" }}>
            <Select
              value={type}
              label="Typ"
              displayEmpty
              size="small"
              onChange={(e) => setType(e.target.value as ItemType)}
            >
              {Object.values(ItemType).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Typ</FormHelperText>
          </FormControl>
          <FormControl>
            <TextField
              size="small"
              placeholder="Palette"
              onChange={(e) => setPaletteId(e.target.value)}
              value={paletteId}
              error={!paletteId}
            />
            <FormHelperText>Palettennummer</FormHelperText>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAllowScan(true);
              scanRef.current?.reset();
            }}
          >
            Starten
          </Button>
        </Stack>
      )}
      <Container maxWidth="md" sx={{ mb: "auto" }}>
        {allowScan && (
          <ScanFormSimplified
            ref={scanRef}
            loading={loading}
            onScan={(ean) => {
              fetchItem(ean);
            }}
            paletteId={paletteId}
          />
        )}
      </Container>
    </Stack>
  );
}

export default ScanKeepaEanRouteV2;
