import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useState } from "react";
import { DEFAULT_PROPERTIES, PRICE_THRESHOLD } from "../constants";
import { useValidateItem } from "../hooks/useValidateItem";
import EditAudiobookProperties from "./EditAudiobookProperties";
import EditCdProperties from "./EditCdProperties";
import EditDvdProperties from "./EditDvdProperties";
import {
  DvdFormat,
  ItemType,
  OfferAll,
  OfferCondition,
  PropertiesAudiobook,
  PropertiesBlueRay,
  PropertiesCd,
  PropertiesDvd,
  ScanItemAll,
} from "./ItemHistory";

function EditItemPrice({
  item,
  onItemUpdate,
  onHighlight,
}: {
  item: ScanItemAll;
  onItemUpdate: (
    item: ScanItemAll,
    properties?: Partial<PropertiesCd | PropertiesDvd | PropertiesAudiobook>,
    offer?: OfferAll
  ) => void;
  onHighlight: (highlight: boolean) => void;
}) {
  const validateItem = useValidateItem();
  const [offer, setOffer] = useState<Partial<OfferAll>>({ condition: OfferCondition.GOOD });
  const [offerPriceSet, setOfferPriceSet] = useState(false);
  const [validate, setValidate] = useState(false);
  const [properties, setProperties] = useState<Partial<PropertiesCd | PropertiesDvd | PropertiesAudiobook>>({
    ...((item.properties?.[0] as PropertiesCd | PropertiesDvd | PropertiesAudiobook | undefined) ??
      DEFAULT_PROPERTIES[item.type]),
  });
  const [price, setPrice] = useState(item.price);
  const [image, setImage] = useState(item.image);
  const [highlight, setHighlight] = useState(item.highlight === true);

  const handleItemUpdate = useCallback(() => {
    const updatedItem = { ...item, price, image, highlight };
    const validation = validateItem(updatedItem, properties, offer);

    if (validation === true) {
      onItemUpdate(updatedItem, properties, offer.ascendingId ? (offer as OfferAll) : undefined);
    } else {
      enqueueSnackbar(typeof validation === "string" ? validation : "Bitte alle Felder ausfüllen", {
        variant: "error",
      });
      setValidate(true);
    }
  }, [validateItem, item, properties, onItemUpdate, price, image, highlight, offer]);

  useEffect(() => {
    setOffer({ condition: OfferCondition.GOOD, price: Math.floor(item.price) });
    setOfferPriceSet(false);
    setPrice(Math.floor(item.price));
    setImage(item.image);
    setHighlight(item.highlight === true);
    setProperties({
      ...((item.properties?.[0] as PropertiesCd | PropertiesDvd | PropertiesAudiobook | undefined) ??
        DEFAULT_PROPERTIES[item.type]),
    });
    setValidate(false);
  }, [item]);

  return (
    <Fragment>
      <Grid container justifyContent="center" spacing={1} mb={3}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Globale Einstellungen
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <TextField
              type="number"
              size="small"
              color={price < PRICE_THRESHOLD ? "error" : "primary"}
              sx={{
                color: price < PRICE_THRESHOLD ? "error.main" : "primary.main",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ fontSize: "0.7em", mr: 1 }}>.99</Typography>
                    <Chip size="small" label="€" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setPrice(parseFloat(e.target.value));
                if (!offerPriceSet) {
                  setOffer({ ...offer, price: parseFloat(e.target.value) });
                }
              }}
              value={price}
              autoFocus={price === 0}
            />
            <FormHelperText>Preis</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: "center" }}>
          <FormControlLabel
            control={<Switch checked={highlight} onChange={(e) => {
              setHighlight(e.target.checked)
              onHighlight(e.target.checked)
            }} />}
            label="Sammeln"
          />
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="contained" onClick={() => handleItemUpdate()}>
            Speichern
          </Button>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth sx={{ position: "relative" }}>
            <TextField size="small" onChange={(e) => setImage(e.target.value)} value={image} />
            <FormHelperText>Bild</FormHelperText>
            {image && (
              <img
                src={image}
                alt="Bild"
                height="180px"
                style={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  transform: "translateX(-100%) translateX(-16px)",
                  marginRight: 5,
                }}
              />
            )}
          </FormControl>
        </Grid>
      </Grid>
      {item.type === ItemType.CD ? (
        <EditCdProperties properties={properties as PropertiesCd} setProperties={setProperties} validate={validate} />
      ) : item.type === ItemType.DVD ? (
        <EditDvdProperties
          properties={properties as PropertiesDvd}
          setProperties={setProperties}
          validate={validate}
          format={DvdFormat.DVD}
        />
      ) : item.type === ItemType.BLUERAY ? (
        <EditDvdProperties
          properties={properties as PropertiesBlueRay}
          setProperties={setProperties}
          validate={validate}
          format={DvdFormat.BLURAY}
        />
      ) : item.type === ItemType.AUDIOBOOK ? (
        <EditAudiobookProperties
          properties={properties as PropertiesAudiobook}
          setProperties={setProperties}
          validate={validate}
        />
      ) : null}
      <Grid container justifyContent="center" spacing={1} mt={2}>
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            Einzelartikel
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControl fullWidth error={validate && !offer.ascendingId}>
            <TextField
              type="number"
              size="small"
              autoFocus={price > 0}
              value={offer.ascendingId}
              onChange={(e) => setOffer({ ...offer, ascendingId: parseInt(e.target.value) })}
            />
            <FormHelperText>Nummer</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth error={validate && !offer.price}>
            <TextField
              type="number"
              size="small"
              color={price < PRICE_THRESHOLD ? "error" : "primary"}
              sx={{
                color: price < PRICE_THRESHOLD ? "error.main" : "primary.main",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography sx={{ fontSize: "0.7em", mr: 1 }}>.99</Typography>
                    <Chip size="small" label="€" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setOffer({ ...offer, price: parseFloat(e.target.value) });
                setOfferPriceSet(true);
              }}
              value={offer.price}
            />
            <FormHelperText>Preis</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth error={validate && !offer.condition}>
            <Select
              value={offer.condition}
              label="Zustand"
              displayEmpty
              size="small"
              onChange={(e) => setOffer({ ...offer, condition: e.target.value as OfferCondition })}
            >
              {Object.values(OfferCondition).map((condition) => (
                <MenuItem key={condition} value={condition}>
                  {condition}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Zustand</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default EditItemPrice;
