import { FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { AudiobookFormat, Language, PropertiesAudiobook } from "./ItemHistory";
import { useMemo } from "react";

function EditAudiobookProperties({
  properties,
  setProperties,
  validate,
}: {
  properties: Partial<PropertiesAudiobook>;
  setProperties: (properties: Partial<PropertiesAudiobook>) => void;
  validate?: boolean;
}) {
  const genres = useMemo(
    () => [
      "Love",
      "Drama",
      "Horror",
      "Comedy",
      "Kids",
      "Wissen",
      "Krimi",
      "Thriller",
      "Märchen",
      "Science Fiction",
      "Dichtung",
      "Magie",
      "Fantasy",
    ],
    []
  );

  return (
    <Grid container spacing={1} sx={{ maxWidth: "640px" }}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          Hörbuch Einstellungen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={validate && !properties.author} fullWidth>
          <TextField
            size="small"
            onChange={(e) => setProperties({ ...properties, author: e.target.value })}
            value={properties.author}
          />
          <FormHelperText>Author</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl error={validate && !properties.title} fullWidth>
          <TextField
            size="small"
            onChange={(e) => setProperties({ ...properties, title: e.target.value })}
            value={properties.title}
          />
          <FormHelperText>Titel</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && !properties.language} fullWidth>
          <Select
            value={properties.language}
            label="Sprache"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, language: e.target.value as Language })}
          >
            {Object.values(Language).map((language) => (
              <MenuItem key={language} value={language}>
                {language}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Sprache</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && !properties.format} fullWidth>
          <Select
            value={properties.format}
            label="Sprache"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, format: e.target.value as AudiobookFormat })}
          >
            {Object.values(AudiobookFormat).map((format) => (
              <MenuItem key={format} value={format}>
                {format}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Sprache</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl error={validate && !properties.genre} fullWidth>
          <Select
            value={properties.genre}
            label="Genre"
            displayEmpty
            size="small"
            onChange={(e) => setProperties({ ...properties, genre: e.target.value })}
          >
            {genres.map((genre) => (
              <MenuItem key={genre} value={genre}>
                {genre}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Genre</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default EditAudiobookProperties;

