import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from "@mui/material";
import { Clear, Download, Search } from "@mui/icons-material";
import { BACKEND_V2_URI } from "../constants";
import { enqueueSnackbar } from "notistack";
import { useDownload } from "../hooks/useDownload";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useUserLogin } from "../hooks/useUserLogin";

interface ScanLog {
  id: number;
  ean: string;
  paletteId: string;
  price: number;
  color: string;
  user: { id: number; username: string };
  createdAt: string;
}

function ScanLogRoute() {
  const { userToken } = useUserLogin();
  const [search, setSearch] = useState("");
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [scanLogs, setScanLogs] = useState<ScanLog[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const download = useDownload("items", "csv");

  const getLogsPage = useCallback(
    (page: number, pageSize: number) => {
      const filters = [];
      if (dateFrom) filters.push(`filter.createdAt=$gte=${dateFrom.toISOString().split("T")[0]}`);
      if (dateTo) filters.push(`filter.createdAt=$lte=${dateTo.toISOString().split("T")[0]}`);
      if (search) filters.push(`filter.search=${search}`);
      return fetch(`${BACKEND_V2_URI}log/user/scan?${filters.join("&")}&limit=${pageSize}&page=${page}`, {
        headers: { Authorization: `Bearer ${userToken}` },
      });
    },
    [search, dateFrom, dateTo, userToken]
  );

  const exportItems = useCallback(async () => {
    setLoading(true);

    const csv = [["EAN", "Palette", "Farbe", "Preis", "Nutzer", "Datum", "Uhrzeit"].join(";")];

    const filter = [];
    if (dateFrom) filter.push(`filter.createdAt=$gte=${dateFrom.toISOString().split("T")[0]}`);
    if (dateTo) filter.push(`filter.createdAt=$lte=${dateTo.toISOString().split("T")[0]}`);
    if (search) filter.push(`filter.search=${search}`);
    const response = await fetch(`${BACKEND_V2_URI}log/user/scan?${filter.join("&")}&limit=999999999`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
    const { data = [], meta } = await response.json();

    for (const log of data) {
      csv.push(
        [
          log.ean,
          log.paletteId,
          log.color,
          log.price,
          log.user?.username,
          log.createdAt?.split("T")[0],
          log.createdAt?.split("T")[1].split(".")[0],
        ].join(";")
      );
    }

    const date = new Date();
    const dateParts = [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
    ];
    download(csv.join("\n"), `scan_logs_export_${dateParts.join("")}`);

    setLoading(false);
  }, [dateFrom, dateTo, download, search, userToken]);

  const searchLogs = useCallback(
    async (resetToPage?: number) => {
      setLoading(true);
      if (resetToPage !== undefined) setPage(resetToPage);

      try {
        const response = await getLogsPage((resetToPage ?? page) + 1, rowsPerPage);
        const { data = [], meta } = await response.json();

        setScanLogs(data);
        setCount(meta.totalItems);
      } catch (error) {
        enqueueSnackbar("Fehler beim Laden des Artikels", { variant: "error" });
      }

      setLoading(false);
    },
    [page, rowsPerPage, getLogsPage]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    searchLogs();
  }, [page, searchLogs]);

  return (
    <Container sx={{ marginTop: 8 }}>
      <Toolbar>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Von"
            value={dateFrom}
            onChange={(e) => setDateFrom(e)}
            slotProps={{
              textField: {
                size: "small",
                sx: { maxWidth: "150px", mr: 2 },
              },
            }}
          />
          <DatePicker
            label="Bis"
            value={dateTo}
            onChange={(e) => setDateTo(e)}
            slotProps={{
              textField: {
                size: "small",
                sx: { maxWidth: "150px", mr: 2 },
              },
            }}
          />
        </LocalizationProvider>
        <Input
          placeholder="Suche"
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") searchLogs(0);
          }}
          value={search}
          endAdornment={
            <IconButton onClick={() => setSearch("")}>
              <Clear />
            </IconButton>
          }
        />
        <IconButton sx={{ mr: "auto" }} disabled={loading} onClick={() => searchLogs(0)}>
          <Search />
        </IconButton>
        <Button disabled={loading} startIcon={<Download sx={{ mr: 1 }} />} onClick={() => exportItems()}>
          Exportieren
        </Button>
      </Toolbar>
      <Paper sx={{ overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "80vh", mt: 3 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>EAN</TableCell>
                <TableCell>Palette</TableCell>
                <TableCell>Farbe</TableCell>
                <TableCell>Preis</TableCell>
                <TableCell>Nutzer</TableCell>
                <TableCell>Datum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scanLogs.map((log, index) => (
                <TableRow key={log.id}>
                  <TableCell>{log.ean}</TableCell>
                  <TableCell>{log.paletteId}</TableCell>
                  <TableCell>{log.color}</TableCell>
                  <TableCell>{log.price}</TableCell>
                  <TableCell>{log.user.username}</TableCell>
                  <TableCell>{log.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default ScanLogRoute;
